import React from 'react';
import './App.css';

const Hamsters = () => (
    <div className="hamsters">
        <img src="/img/hamster.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster3.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster2.gif" alt="" />
        <img src="/img/hamster.gif" alt="" />
    </div>
)

function App() {
    return (
    <div className="App">
        <Hamsters />
        <Hamsters />
        <Hamsters />
        <Hamsters />
        <Hamsters />
        <audio
            controls
            autoPlay
            loop
            src="/audio/hamsterdance.mp3">
                Your browser does not support the
                audio element.
        </audio>
    </div>
    );
}

export default App;
